/** @jsxImportSource react */
import { default as Image } from '@react/shared/components/Image';
import { Link } from '@react/system';
import React from 'react';

import { toolItem } from './Styles';
import { ToolCardProps } from '../types';

const ToolCard: React.FC<ToolCardProps> = ({
  item,
  eventProps,
  target = '_self',
}) => (
  <Link
    href={item.url}
    eventProps={eventProps}
    target={target}
    className='hover:no-underline'
  >
    <div className={toolItem()}>
      <div className='aspect-w-3 aspect-h-2 flex w-full flex-col items-center justify-center gap-2 rounded-lg'>
        <Image
          src={item.src}
          path={item.path}
          alt={item.title}
          layout='raw'
          objectFit='cover'
          className='h-full w-full rounded-lg object-cover'
          width={400}
          height={400}
        />
      </div>
      <div className='flex-grow space-y-2'>
        <h3 className='font-t-s'>{item.title}</h3>
        <div
          className='text-sm'
          dangerouslySetInnerHTML={{
            __html: item.description,
          }}
        />
      </div>
    </div>
  </Link>
);

export default ToolCard;
