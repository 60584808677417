import {
  RESOURCE_CENTER_PATH,
  TOOLS_PATH
} from '@sly/core/constants/dashboardAppPaths';

export function transformArticles(articles: any[]): any[] {
  return articles.map((guide: any) => {
    const path = guide?.mainImg?.path;
    return {
      title: guide.title,
      description: guide?.description,
      path,
      url: `${RESOURCE_CENTER_PATH}/${guide?.mainTopic.slug}/${guide?.slug}`
    };
  });
}

export function transformChecklists(checklists: any[]): any[] {
  return checklists.map((checklist: any) => {
    const path = checklist?.mainImage?.path;
    return {
      title: checklist.name,
      description: checklist?.shortDescription || checklist?.description,
      path,
      url: checklist?.redirectURL || `${TOOLS_PATH}/${checklist.slug}`
    };
  });
}

export function transformSeniorLivingTools(tools: any[]): any[] {
  return tools.map((tool: any) => {
    const path = tool?.mainImage?.path;
    return {
      title: tool.name,
      description: tool?.shortDescription || tool?.description,
      path,
      url: tool?.redirectURL || `${TOOLS_PATH}/${tool.slug}`
    };
  });
}

export function tranformSeniorLivingTypes(seniorLivingTypes: any[]) {
  return seniorLivingTypes.map((type: any) => {
    const path = type?.mainImg?.path;
    return {
      title: type.title,
      description: type?.shortDescription || type?.description,
      path,
      url: `/${type.slug}`
    };
  });
}
