/** @jsxImportSource react */
import { BreadCrumbsProps } from '@react/shared/components/BreadCrumb/BreadCrumbs.types';
import { Link } from '@react/system';
import { arrayOf, object, shape, string } from 'prop-types';
import React from 'react';

const BreadCrumb = ({ items, innerRef, ...props }: BreadCrumbsProps) => (
  <nav ref={innerRef} {...props}>
    <ol className='font-b-s m-0 block list-none p-0'>
      {items.map((item, index) => {
        const { label, path, eventProps } = item;

        const isLast = index === items.length - 1;
        const metaContent = index + 1;

        const content = (
          <>
            <meta content={metaContent.toString(10)} />
            <span
              className={`${isLast ? 'text-slate-base' : 'text-viridian-base'}`}
              data-tp-id='gen-span-1fabd0fa-ee2e-4081-b7dc-a26ab25be875'
            >
              {label}
            </span>
          </>
        );

        return (
          <li
            key={`${path} ${label}`}
            className='font-b-s inline-block capitalize'
          >
            {!isLast && (
              <Link
                to={path}
                eventProps={eventProps}
                data-tp-id='gen-Link-3155db10-4f98-48be-9e09-d4fe4ed0807f'
              >
                {content}
              </Link>
            )}
            {isLast && content}
            {!isLast && (
              <span
                className='mx-xxs my-0'
                data-tp-id='gen-span-84771ddc-e8ed-4aea-ab31-5d7d0a2d2029'
              >
                /
              </span>
            )}
          </li>
        );
      })}
    </ol>
  </nav>
);

BreadCrumb.propTypes = {
  items: arrayOf(
    shape({
      label: string.isRequired,
      path: string.isRequired,
      event: object,
    })
  ).isRequired,
  innerRef: object,
  size: string,
};

BreadCrumb.defaultProps = {
  size: 'body-small',
};

export default BreadCrumb;
