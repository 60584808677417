/** @jsxImportSource react */
import { cn } from '@common/lib/clsxm';
import BreadCrumb from '@react/shared/components/BreadCrumb';
import Footer from '@react/shared/components/Footer';
import Header from '@react/shared/components/Header/Header.container';
import { Button } from '@react/system';
import { Share } from '@sly/icons/react';
import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';

type ComponentProps = {
  children: ReactNode;
  className?: string;
};

export function ToolGrid({ children, className }: ComponentProps) {
  return (
    <div
      className={cn(
        'no-scrollbar flex gap-6 overflow-x-auto md:grid md:grid-cols-[repeat(auto-fill,minmax(32%,1fr))] md:overflow-x-visible lg:grid-cols-[repeat(auto-fill,minmax(31%,1fr))] lg:gap-8',
        className
      )}
    >
      {children}
    </div>
  );
}

export function ToolImageContainer({ children, className }: ComponentProps) {
  return (
    <div
      className={cn(
        'flex aspect-[3/2] flex-col items-center justify-center gap-2',
        className
      )}
    >
      {children}
    </div>
  );
}

export function ToolTitle({ children, className }: ComponentProps) {
  return <h3 className={cn('font-t-s mb-2', className)}>{children}</h3>;
}

export function ToolDescription({ children, className }: ComponentProps) {
  return <p className={cn('text-sm', className)}>{children}</p>;
}

export const toolItem = cva('', {
  variants: {
    variant: {
      default:
        'flex w-64 flex-shrink-0 flex-col gap-4 overflow-hidden bg-white text-slate-base hover:no-underline md:w-auto',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export function ToolLayout({
  children,
  className,
  breadCrumbItems,
}: ComponentProps & {
  breadCrumbItems: { label: string; path: string }[];
}) {
  return (
    <>
      <Header />
      <main
        className={cn(
          'gap-6 space-y-2 bg-slate-lighter-95 py-4 sm:space-y-4 md:space-y-6 md:px-8 md:pt-0 md:pb-8',
          className
        )}
      >
        {breadCrumbItems && (
          <BreadCrumb
            items={breadCrumbItems}
            className='container px-4 pt-4 md:px-0'
          />
        )}
        {children}
      </main>
      <Footer />
    </>
  );
}

export function ToolHero({
  heading,
  subheading,
  onShare,
}: {
  heading: string;
  subheading?: string;
  onShare?: () => void;
}) {
  return (
    <div className='grid grid-cols-[auto,max-content] justify-between gap-4'>
      <div className='max-w-3xl'>
        <h1 className='font-t-l mb-2 font-bold capitalize-first'>{heading}</h1>
        {subheading && (
          <div
            dangerouslySetInnerHTML={{
              __html: subheading,
            }}
          />
        )}
      </div>
      {onShare && (
        <Button
          className='h-max py-s px-l md:px-s'
          onClick={onShare}
          variant='neutral'
          eventProps={{
            text: 'Share',
            location: `${heading} tool`,
          }}
        >
          <Share className='mr-xs' />
          Share
        </Button>
      )}
    </div>
  );
}
