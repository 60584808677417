import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M6.07175 23.2392C5.44203 23.2392 4.90543 23.0174 4.46195 22.574C4.01846 22.1305 3.79672 21.5939 3.79672 20.9642V10.3707C3.79672 9.74098 4.01846 9.20439 4.46195 8.7609C4.90543 8.31742 5.44203 8.09568 6.07175 8.09568H7.725C8.0442 8.09568 8.31358 8.20546 8.53315 8.42503C8.75271 8.64459 8.8625 8.91398 8.8625 9.23318C8.8625 9.55239 8.75271 9.82178 8.53315 10.0413C8.31358 10.2609 8.0442 10.3707 7.725 10.3707H6.07175V20.9642H17.9282V10.3707H16.275C15.9558 10.3707 15.6864 10.2609 15.4668 10.0413C15.2473 9.82178 15.1375 9.55239 15.1375 9.23318C15.1375 8.91398 15.2473 8.64459 15.4668 8.42503C15.6864 8.20546 15.9558 8.09568 16.275 8.09568H17.9282C18.558 8.09568 19.0946 8.31742 19.538 8.7609C19.9815 9.20439 20.2033 9.74098 20.2033 10.3707V20.9642C20.2033 21.5939 19.9815 22.1305 19.538 22.574C19.0946 23.0174 18.558 23.2392 17.9282 23.2392H6.07175ZM10.8625 5.10603L10.0581 5.91038C9.83423 6.13429 9.56901 6.24309 9.2625 6.23675C8.95598 6.2304 8.69076 6.11527 8.46685 5.89135C8.25561 5.66745 8.14682 5.40224 8.14047 5.0957C8.13414 4.78919 8.24293 4.52397 8.46685 4.30005L11.1984 1.56255C11.4263 1.33465 11.6935 1.2207 12 1.2207C12.3065 1.2207 12.5737 1.33465 12.8016 1.56255L15.5331 4.30003C15.7444 4.51126 15.85 4.77331 15.85 5.08618C15.85 5.39904 15.7444 5.66743 15.5331 5.89133C15.3092 6.11524 15.0408 6.2272 14.728 6.2272C14.4151 6.2272 14.1467 6.11524 13.9228 5.89133L13.1375 5.106V15.0718C13.1375 15.391 13.0277 15.6604 12.8081 15.8799C12.5886 16.0995 12.3192 16.2093 12 16.2093C11.6808 16.2093 11.4114 16.0995 11.1918 15.8799C10.9723 15.6604 10.8625 15.391 10.8625 15.0718V5.10603Z"/>
</svg>`;

const Share = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='share' svg={svg} {...props} />
  )
);

Share.displayName = 'ShareIcon';

export default Share;
